import auth0 from 'auth0-js';
import { useEffect, useRef } from "react";


function CrossAuthComponent() {
  const auth0Client = useRef(new auth0.WebAuth({
    clientID: '1xRNqTRKZTSfO6KcIhtRy41boKiu87Ro',
    domain: 'https://passwordless.dev.tatsam.in',
    redirectUri: 'https://passwordless.dev.tatsam.in'
  }));
  useEffect(() => {
    auth0Client.current.crossOriginVerification();
  }, [])


  return (
    <div>This is CrossAuth Page</div>
  );
}

export default CrossAuthComponent;
