import './App.css';
import auth0 from 'auth0-js';
import { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route }
  from 'react-router-dom';
import CrossAuthComponent from './crossAuth';


function Passwordless() {
  console.log("BUILD VAr",process.env.REACT_APP_DUMMY_VARIABLE);
  const [code, setCode] = useState('');
  const [num, setNum] = useState('+917985111314');

  const [domain, setDomain] = useState('login.dev.tatsam.in');
  const [clientID, setclientID] = useState('1xRNqTRKZTSfO6KcIhtRy41boKiu87Ro');
  const [redirectUri, setredirectUri] = useState('https://passwordless.dev.tatsam.in');
  const [scope, setscope] = useState('openid email profile offline_access');
  const [responseType, setresponseType] = useState('token id_token');
  //const [organization, setorganization] = useState('org_t7oGPh45YJ6zhSBW');
  const [audience, setaudience] = useState('https://tatsam.in/user');

  const webAuth = useRef(new auth0.WebAuth({
    domain: 'login.dev.tatsam.in',
    clientID: '1xRNqTRKZTSfO6KcIhtRy41boKiu87Ro',
    redirectUri: 'https://passwordless.dev.tatsam.in',
    responseType: 'token id_token',
    //organization: 'org_t7oGPh45YJ6zhSBW',
    audience: 'https://tatsam.in/user'
  }));

  useEffect(() => {
    console.log("Resetting Client!!!")
    webAuth.current = new auth0.WebAuth({
      domain,
      clientID,
      redirectUri,
      scope,
      responseType,
      //organization,
      audience,
    });
  }, [
    domain,
    clientID,
    redirectUri,
    scope,
    responseType,
    //organization,
    audience,
  ])

  function sendOtp(e) {
    e.preventDefault();

    console.log("SENDING OTP!!!")

    webAuth.current.passwordlessStart({
      connection: 'sms',
      send: 'code',
      phoneNumber: num,
      // authParams: {
      //   state: 'gJv10xx-wcMddwjJ4pXNQzRZ37JP8ZN9',//remove and then check
      //   nonce: '1234'
      // }
      //email: 'utkarsh@tatsam.in',

    }, function (err, result) {
      if (err) {
        console.log(err);
      } else {
        console.log(result);
      }
    });
  }

  function verify(e) {
    e.preventDefault();

    console.log("SUBMITTED!!")

    webAuth.current.passwordlessLogin({
      connection: 'sms',
      phoneNumber: '+917985111314',
      //email: 'utkarsh@tatsam.in',
      verificationCode: code,
      // state: 'gJv10xx-wcMddwjJ4pXNQzRZ37JP8ZN9',
      // nonce: '1234',
    }, function (err, result) {
      if (err) {
        console.log(err);
      } else {
        console.log(result);
      }
    });
  }

  function fetchAuth0Details(e) {
    e.preventDefault();
    webAuth.current.parseHash({
      hash: window.location.hash,
      // state: 'gJv10xx-wcMddwjJ4pXNQzRZ37JP8ZN9',
      // nonce: '1234',
    }, function (err, authResult) {
      if (err) {
        return console.log(err);
      }

      webAuth.current.client.userInfo(authResult.accessToken, function (err, user) {
        console.log(user);
        console.log(user.accessToken);
      });
    });

  }
  return <div>
    <form onSubmit={e => e.preventDefault()}>
      <label>Domain</label>
      <input onChange={(e) => setDomain(e.target.value)} value={domain}></input>
      <br />
      <label>clientID</label>
      <input onChange={(e) => setclientID(e.target.value)} value={clientID}></input>
      <br />
      <label>redirectUri</label>
      <input onChange={(e) => setredirectUri(e.target.value)} value={redirectUri}></input>
      <br />
      <label>scope</label>
      <input onChange={(e) => setscope(e.target.value)} value={scope}></input>
      <br />
      <label>responseType</label>
      <input onChange={(e) => setresponseType(e.target.value)} value={responseType}></input>
      <br />
      {/* <label>organization</label>
      <input onChange={(e) => setorganization(e.target.value)} value={organization}></input>
      <br /> */}
      <label>audience</label>
      <input onChange={(e) => setaudience(e.target.value)} value={audience}></input>
    </form>
    <form onSubmit={sendOtp}>
      <label>Mobile</label>
      <input onChange={(e) => setNum(e.target.value)} value={num}></input>
      <input type='submit' value='Send OTP'></input>
    </form>
    <form onSubmit={verify}>
      <label>OTP</label>
      <input onChange={(e) => setCode(e.target.value)} value={code}></input>
      <input type='submit' value='Verify'></input>
    </form>
    <form onSubmit={fetchAuth0Details}>
      <input type='submit' value='Fetch Auth0 Details'></input>
    </form>
  </div>
}

function App() {
  console.log("Rendering");
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path='/' element={<Passwordless />} />
          <Route exact path='/auth' element={<CrossAuthComponent />} />
        </Routes>
      </Router>
    </div>
  );
}


export default App;
